import React, { Component } from "react";
import "./../../Admin.scss";
import "./../../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonIcon,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonButtons,
} from "@ionic/react";
import { fetchAPI } from "./../../../utils/APIUtil";
import { log, LogLevel } from "../../../utils/LogUtil";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import {
  ProductInfo,
  ProductStatus,
  ProductStatusName,
} from "../../../models/Model.Sale";
import { BoardType } from "../../../store/board/types";
import Button from "../../../components/atoms/Button";
import { Flex } from "../../../components/atoms/Layout";
import Badge from "../../../components/atoms/Badge/Badge";
import Tag from "../../../components/atoms/Tag";
import { ColorSystem } from "../../../components/design/design-system";

const API = {
  PRODUCT_LIST: {
    method: "GET",
    path: "/admin/sale/product/list",
    contentType: "application/json",
  },
};

const ROW_CNT = 30;

type Props = {
  onSelect: (product: ProductInfo) => void;
  onClose?: () => void;
};

type State = {
  status: ProductStatus;
  products: ProductInfo[];
};

export const ProductStatusColor = {
  [ProductStatus.NONE]: "Gray" as ColorSystem,
  [ProductStatus.PREPARING]: "Green" as ColorSystem,
  [ProductStatus.ONGOING]: "Blue" as ColorSystem,
  [ProductStatus.SHORT]: "Red" as ColorSystem,
  [ProductStatus.CLOSED]: "Gray" as ColorSystem,
};

class ProductSelect extends Component<Props, State> {
  finished = false;
  loading = false;
  productList = null;

  state = {
    full: false,
    status: ProductStatus.NONE,
    products: [],
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  onScroll = (event) => {
    let target: any = event.target;
    // log(LogLevel.UI_EVENT, "BoardList:scroll", event.detail.scrollTop, target.clientHeight, this.contentList.clientHeight);
    if (
      !this.loading &&
      target.scrollTop + target.clientHeight >
        this.productList.clientHeight * 0.9
    ) {
      log(
        LogLevel.UI_EVENT,
        "ProductSelect:onScroll",
        target.scrollTop,
        target.clientHeight,
        this.productList.clientHeight
      );
      this.fetchDataMore();
    }
  };

  onStatusChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let status: ProductStatus = parseInt(e.detail.value);
    this.changeStatus(status);
  };

  changeStatus = (status: ProductStatus) => {
    this.setState({ status: status });
  };

  refresh = (product?: ProductInfo) => {
    log(LogLevel.UI_EVENT, "ProductSelect:refresh", product);
    if (product) {
      let products = [...this.state.products];
      let index = products.findIndex((item) => item.id == product.id);
      if (index >= 0) {
        products[index] = { ...products[index], ...product };
        this.setState({ products });
      } else {
        products.push(product);
        this.setState({ products });
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:ProductSelect:fetchData");
    this.loading = true;
    fetchAPI(
      API.PRODUCT_LIST,
      "",
      { start: 0, count: ROW_CNT },
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      if (result && !result.error) {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:ProductSelect:fetchData result",
          result
        );

        this.finished = false;
        if (result.data && result.data.length < ROW_CNT) this.finished = true;

        this.setState({ products: result.data });
        this.loading = false;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:ProductSelect:fetchData result",
          result
        );
        this.finished = true;
        this.setState({ products: [] });
      }
    });
  };

  fetchDataMore = async () => {
    log(
      LogLevel.UI_DATA_LOAD,
      "Admin:ProductSelect:fetchDataMore",
      this.finished
    );
    this.loading = true;
    if (!this.finished) {
      fetchAPI(
        API.PRODUCT_LIST,
        "",
        {
          start: this.state.products.length,
          count: ROW_CNT,
          status: this.state.status,
        },
        null,
        getGlobal(GlobalKey.TOKEN)
      ).then((result) => {
        if (result && !result.error) {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:ProductSelect:fetchData result",
            result
          );

          this.finished = false;
          if (result.data && result.data.length < ROW_CNT) this.finished = true;

          this.setState({ products: [...this.state.products, ...result.data] });
          this.loading = false;
        } else {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:ProductSelect:fetchData result",
            result
          );
          this.finished = true;
          this.loading = false;
        }
      });
    }
  };

  onSelect = (product: ProductInfo) => {
    log(LogLevel.UI_EVENT, "onSelect", product);
    this.props.onSelect(product);
  };

  onNew = () => {
    let product: ProductInfo = {
      id: 0,
      name: "",
      code: "",
      description: "",
      company: "",
      contentId: 0,
      content: {
        boardsCategoriesId: BoardType.PRODUCT,
        groupParent: 0,
        isAnonymous: false, // 익명 게시 여부
        subject: "",
        bodyHtml: "",
        bodyText: "",
      },
      status: ProductStatus.PREPARING,
      priceOriginal: 0, // 판매단위 가격
      price: 0, // 실제 판매단위 가격
      deliveryFee: 0, // 배송비 가격
      orderAmountLimitCondition: {}, // 인당 판매제한 수량 로직(판매단위)
      deliveryFeeCondition: {}, // 인당 판매제한 수량 로직(판매단위)
      image: "", // main image : 1x1
      bannerImage: "", // banner image
      pharmacyOnly: false,
    };

    this.props.onSelect(product);
  };

  render() {
    return (
      <div className="admin-container" onScroll={this.onScroll}>
        {this.props.onClose && (
          <IonHeader class="common-header" no-border>
            <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  class="common-header-toolbar-button"
                  onClick={this.props.onClose}
                >
                  <IonIcon name="arrow-back" mode="ios" />
                </IonButton>
                <IonLabel
                  class="common-header-title"
                  onClick={this.props.onClose}
                >
                  제품선택
                </IonLabel>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        <div className="register2-work-type-container">
          <div
            className={
              this.state.status == ProductStatus.NONE
                ? "register2-work-type-selected"
                : "register2-work-type-unselected"
            }
            onClick={() => this.changeStatus(ProductStatus.NONE)}
          >
            ALL
          </div>
          <div
            className={
              this.state.status == ProductStatus.PREPARING
                ? "register2-work-type-selected"
                : "register2-work-type-unselected"
            }
            onClick={() => this.changeStatus(ProductStatus.PREPARING)}
          >
            {ProductStatus[ProductStatus.PREPARING]}
          </div>
          <div
            className={
              this.state.status == ProductStatus.ONGOING
                ? "register2-work-type-selected"
                : "register2-work-type-unselected"
            }
            onClick={() => this.changeStatus(ProductStatus.ONGOING)}
          >
            {ProductStatus[ProductStatus.ONGOING]}
          </div>
          <div
            className={
              this.state.status == ProductStatus.SHORT
                ? "register2-work-type-selected"
                : "register2-work-type-unselected"
            }
            onClick={() => this.changeStatus(ProductStatus.SHORT)}
          >
            {ProductStatus[ProductStatus.SHORT]}
          </div>
          <div
            className={
              this.state.status == ProductStatus.CLOSED
                ? "register2-work-type-selected"
                : "register2-work-type-unselected"
            }
            onClick={() => this.changeStatus(ProductStatus.CLOSED)}
          >
            {ProductStatus[ProductStatus.CLOSED]}
          </div>
        </div>
        <div className="user-admin-list-container">
          <Flex>
            <Button
              size={"Medium"}
              type={"Icon"}
              variant={"Outlined"}
              color={"Primary"}
              icon="Refresh"
            />
            <IonButton expand="full" onClick={this.fetchData}>
              새로운 제품 만들기
            </IonButton>
          </Flex>

          <IonList
            ref={(ref) => {
              this.productList = ref;
            }}
          >
            {this.state.products.map((item: ProductInfo, index: number) => {
              if (
                this.state.status != ProductStatus.NONE &&
                item.status != this.state.status
              )
                return null;
              return (
                <div
                  key={index.toString()}
                  className="common-container-row common-flex-align-center"
                  onClick={() => this.onSelect(item)}
                >
                  <div className="user-admin-list-item-container common-flex-grow">
                    <Flex alignItems="center">
                      <span>
                        {item.id} - {item.name}
                      </span>
                      <Tag
                        variant={"Tinted"}
                        color={ProductStatusColor[item.status]}
                      >
                        {ProductStatusName[item.status]}
                      </Tag>
                    </Flex>
                  </div>
                </div>
              );
            })}
          </IonList>
        </div>
      </div>
    );
  }
}

export default ProductSelect;
