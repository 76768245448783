import { action, createAction, createAsyncAction } from "typesafe-actions";
import {
  BoardState,
  BoardActionTypes,
  BoardListState,
  BoardType,
  BoardSortType,
} from "./types";
import {
  BoardContent,
  BoardContentSummary,
  BoardContentSummaryGroup,
  DailyQuiz,
} from "../../models/Model.Board";

export const refreshBoards = createAction(
  BoardActionTypes.BOARD_REFRESH,
  (resolve) => (board: BoardType = BoardType.ALL) => resolve(board)
);

export const getBoards = createAction(
  BoardActionTypes.BOARDS_FETCH,
  (resolve) => (force: boolean = false) => resolve(force)
);

export const getBoard = createAction(
  BoardActionTypes.BOARD_FETCH,
  (resolve) => (board: number, count: number = 0) => resolve(board, count)
);

export const sortBoard = createAction(
  BoardActionTypes.BOARD_SORT,
  (resolve) => (board: number, sort: BoardSortType) => resolve(board, sort)
);

export const filterBoard = createAction(
  BoardActionTypes.BOARD_FILTER,
  (resolve) => (board: number, filter: number) => resolve(board, filter)
);

export const changeUnionBoard = createAction(
  BoardActionTypes.BOARD_UNION_CHANGE,
  (resolve) => (categories: number[], tabId: number = BoardType.UNION_TAB) =>
    resolve(categories, tabId)
);

export const getBoardUpdates = createAction(
  BoardActionTypes.BOARD_FETCH_NEW,
  (resolve) => (board: number, force: boolean = false) => resolve(board, force)
);

export const getBoardReloadTop10 = createAction(
  BoardActionTypes.BOARD_FETCH_RELOAD,
  (resolve) => (board: number) => resolve(board)
);

export const getContent = createAction(
  BoardActionTypes.CONTENT_FETCH,
  (resolve) => (id: number) => resolve(id)
);

export const getSummary = createAction(
  BoardActionTypes.SUMMARY_FETCH,
  (resolve) => (id: number) => resolve(id)
);

export const getSummaryGroupCount = createAction(
  BoardActionTypes.SUMMARY_GROUP_COUNT_FETCH,
  (resolve) => (keyword: string) => resolve(keyword)
);

export const getSummaryGroup = createAction(
  BoardActionTypes.SUMMARY_GROUP_FETCH,
  (resolve) => (keyword: string) => resolve(keyword)
);

export const setSummaryGroup = createAction(
  BoardActionTypes.SUMMARY_GROUP_UPDATE,
  (resolve) => (group: BoardContentSummaryGroup) => resolve(group)
);

export const clearSummary = createAction(
  BoardActionTypes.SUMMARY_CLEAR,
  (resolve) => (id: number) => resolve(id)
);

export const setSummary = createAction(
  BoardActionTypes.SUMMARY_UPDATE,
  (resolve) => (summary: BoardContentSummary) => resolve(summary)
);

export const loadLink = createAction(
  BoardActionTypes.LINK_FETCH,
  (resolve) => (url: string, contentId: number) => resolve(url, contentId)
);

export const loadCommentLink = createAction(
  BoardActionTypes.COMMENT_LINK_FETCH,
  (resolve) => (url: string, contentId: number) => resolve(url, contentId)
);

export const updateContent = createAction(
  BoardActionTypes.CONTENT_UPDATE,
  (resolve) => (content: BoardContent) => resolve(content)
);

export const visitBoard = createAction(
  BoardActionTypes.BOARD_VISIT,
  (resolve) => (board: number) => resolve(board)
);

export const fetchBoard = createAsyncAction(
  BoardActionTypes.BOARD_REQUEST,
  BoardActionTypes.BOARD_SUCCESS,
  BoardActionTypes.BOARD_ERROR
)<any, any, any>(); // <string, BoardList, Error>();

export const fetchBoards = createAsyncAction(
  BoardActionTypes.BOARDS_REQUEST,
  BoardActionTypes.BOARDS_SUCCESS,
  BoardActionTypes.BOARDS_ERROR
)<any, any, any>(); // <string, BoardList, Error>();

export const fetchContent = createAsyncAction(
  BoardActionTypes.CONTENT_REQUEST,
  BoardActionTypes.CONTENT_SUCCESS,
  BoardActionTypes.CONTENT_ERROR
)<any, any, any>(); // <number, BoardContent, Error>();

export const fetchSummaryGroup = createAsyncAction(
  BoardActionTypes.SUMMARY_GROUP_REQUEST,
  BoardActionTypes.SUMMARY_GROUP_SUCCESS,
  BoardActionTypes.SUMMARY_GROUP_ERROR
)<any, any, any>(); // <number, BoardContentSummary, Error>();

export const fetchSummary = createAsyncAction(
  BoardActionTypes.SUMMARY_REQUEST,
  BoardActionTypes.SUMMARY_SUCCESS,
  BoardActionTypes.SUMMARY_ERROR
)<any, any, any>(); // <number, BoardContentSummary, Error>();

export const fetchLink = createAsyncAction(
  BoardActionTypes.LINK_REQUEST,
  BoardActionTypes.LINK_SUCCESS,
  BoardActionTypes.LINK_ERROR
)<any, any, any>(); // <number, BoardContent, Error>();

export const getFilePath = createAction(
  BoardActionTypes.FILE_PATH_FETCH,
  (resolve) => () => resolve()
);

export const fetchFilePath = createAsyncAction(
  BoardActionTypes.FILE_PATH_REQUEST,
  BoardActionTypes.FILE_PATH_SUCCESS,
  BoardActionTypes.FILE_PATH_ERROR
)<void, string, any>(); // <number, BoardContent, Error>();

export const getBizBoard = createAction(
  BoardActionTypes.BIZ_BOARD_FETCH,
  (resolve) => (
    option: { bizCategoryId?: number; bizUseId?: string },
    count: number = 0
  ) => resolve(option, count)
);

export const fetchBizBoard = createAsyncAction(
  BoardActionTypes.BIZ_BOARD_REQUEST,
  BoardActionTypes.BIZ_BOARD_SUCCESS,
  BoardActionTypes.BIZ_BOARD_ERROR
)<any, any, any>(); // <string, BoardList, Error>();

export const sortBizBoard = createAction(
  BoardActionTypes.BIZ_BOARD_SORT,
  (resolve) => (
    option: { bizCategoryId?: number; bizUseId?: string },
    sort: BoardSortType
  ) => resolve(option, sort)
);

export const loadDailyQuizList = createAction(
  BoardActionTypes.DAILY_QUIZ_LIST_FETCH,
  (resolve) => (options: { startDate: string; endDate: string }) =>
    resolve(options)
);

export const fetchDailyQuizList = createAsyncAction(
  BoardActionTypes.DAILY_QUIZ_LIST_REQUEST,
  BoardActionTypes.DAILY_QUIZ_LIST_SUCCESS,
  BoardActionTypes.DAILY_QUIZ_LIST_ERROR
)<any, any, any>(); // <number, DailyQuiz, Error>();

export const loadDailyQuiz = createAction(
  BoardActionTypes.DAILY_QUIZ_FETCH,
  (resolve) => (date: string) => resolve(date)
);

export const fetchDailyQuiz = createAsyncAction(
  BoardActionTypes.DAILY_QUIZ_REQUEST,
  BoardActionTypes.DAILY_QUIZ_SUCCESS,
  BoardActionTypes.DAILY_QUIZ_ERROR
)<any, any, any>(); // <number, DailyQuiz[], Error>();

export const setDailyQuiz = createAction(
  BoardActionTypes.DAILY_QUIZ_UPDATE,
  (resolve) => (dailyQuiz: DailyQuiz) => resolve(dailyQuiz)
);
