import React, { Component } from "react";
import { connect } from "react-redux";
import { actions, RootState } from "../../../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonLabel,
  IonToolbar,
} from "@ionic/react";
import "./../../Admin.scss";
import { log, LogLevel } from "../../../utils/LogUtil";
import { GlobalKey, getGlobal } from "../../../utils/GlobalUtil";
import { fetchAPI } from "../../../utils/APIUtil";
import { UIPopupType } from "../../../store/ui/types";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import { getOS } from "../../../utils/DeviceUtil";

import DownloadUtil from "../../../utils/DownloadUtil";
import * as API from "../../../API.json";
import { BizUserInfo, OrderInfo } from "../../../models/Mode.Biz";
import { OrderContentType } from "../../../models/Model.User";
import { COLOR_SYSTEM } from "../../../components/design/design-system";




const ADMIN_API = {
  PARTNERS_ORDERS: {
    method: "get",
    path: "/admin/allinone/orders",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onSelect: (order: OrderInfo) => void;
    onBack?: () => void;
    showingPartners: boolean;
  };

type State = {
  items: OrderInfo[];
  type: number;
  dateField: string;
  dateStart: string;
  dateStartWarn: boolean;
  dateEnd: string;
  dateEndWarn: boolean;
  finished: boolean;
  loading: boolean;
  selectedIndex: number;
  orderBy: string;
  orderAsc: boolean;
  unitCount: number;
  contentType: OrderContentType
};

class AllInOneOrderList extends Component<Props, State> {
  type: number = 1;
  dateField: string = "";
  dateStart: string = "";
  dateEnd: string = "";

  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
      type: 1,
      finished: false,
      loading: false,
      dateField: "alive",
      dateStart: getDateStringFromToday({ weeks: -1 }),
      dateStartWarn: false,
      dateEnd: getDateStringFromToday({ days: 1 }),
      dateEndWarn: false,
      selectedIndex: -1,
      orderBy: "",
      orderAsc: false,
      unitCount: 100,
      contentType: this.props.showingPartners ? OrderContentType.PARNTERS : OrderContentType.JOBPOST
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  refresh = () => {
    this.fetchData();
  };

  fetchData = () => {
    if (this.state.dateStartWarn || this.state.dateEndWarn) return;

    this.type = this.state.type;
    this.dateField = this.state.dateField;
    this.dateStart = this.state.dateStart;
    this.dateEnd = this.state.dateEnd;

    this.setState({ loading: true, items: [] });
    fetchAPI(
      ADMIN_API.PARTNERS_ORDERS,
      "",
      {
        start: 0,
        count: this.state.unitCount,
        startDate: this.state.dateStart,
        endDate: this.state.dateEnd,
        dateField: this.state.dateField,
        contentType: this.props.showingPartners? OrderContentType.PARNTERS : OrderContentType.JOBPOST, 
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      log(LogLevel.UI_EVENT, "AllInOneOrderList:fetchCompanyData Offer"),
        result;
      if (result && !result.error) {
        this.setState({
          selectedIndex: -1,
          items: result.data,
          finished: result.data.length < this.state.unitCount,
          loading: false,
          orderBy: "",
        });
      } else {
        this.setState({
          selectedIndex: -1,
          items: [],
          finished: true,
          loading: false,
        });
      }
    });
  };


  checkingEndAt = (endAt : string) =>{

    const endDate = new Date(endAt);
    const currentDate = new Date();

    const diffInTime = endDate.getTime() - currentDate.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    log(LogLevel.ALL, "AllInOneOrderList:diffInDays", diffInDays, endAt, currentDate, endDate, diffInTime)

    if(diffInDays < 30 && diffInDays > 0){
      return true
    } 
    return false
  }


  fetchDataMore = async () => {
    if (this.state.loading == true) return;
    this.setState({ loading: true });
    fetchAPI(
      ADMIN_API.PARTNERS_ORDERS,
      "",
      {
        applies: 1,
        withEnded: 1,
        start: this.state.items.length,
        count: this.state.unitCount,
        startDate: this.dateStart,
        endDate: this.dateEnd,
        dateField: this.dateField,
        withOwner: 1,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      log(LogLevel.UI_EVENT, "AllInOneOrderList:fetchDataMore Offer", result);
      if (result && !result.error) {
        let items = [...this.state.items, ...result.data];
        if (this.state.orderBy) {
          items = this.sort(items, this.state.orderBy, this.state.orderAsc);
        }
        this.setState({
          items,
          finished: result.data.length < this.state.unitCount,
          loading: false,
        });
      } else {
        this.setState({ finished: true, loading: false });
      }
    });
  };

  onSetOrder(field: string) {
    let asc: boolean = this.state.orderAsc;

    if (this.state.orderBy == field) {
      asc = !asc;
    }
    let items = [...this.state.items];
    items = this.sort(items, field, asc);
    this.setState({ items, orderBy: field, orderAsc: asc });
  }

  sort(data: any[], field: string, asc: boolean) {
    data = data.sort((a: OrderInfo, b: OrderInfo) => {
      let tokens = field.split(".");
      let aCmp = 0,
        bCmp = 0;
      if (tokens.length == 1) {
        aCmp = a[tokens[0]];
        bCmp = b[tokens[0]];
      } else if (tokens.length == 2) {
        if (tokens[0]) {
          aCmp = a[tokens[0]] ? a[tokens[0]][tokens[1]] : "ZZZZ";
          bCmp = b[tokens[0]] ? b[tokens[0]][tokens[1]] : "ZZZZ";
        } else {
          aCmp = a.details[0][tokens[1]];
          bCmp = b.details[0][tokens[1]];
        }
      }

      let rvalue = 0;
      if (aCmp > bCmp) rvalue = -1;
      else if (aCmp < bCmp) rvalue = 1;

      if (!asc) rvalue *= -1;

      return rvalue;
    });
    return data;
  }

  onDateChange = (e, field) => {
    let dateString = e.target.value;
    let dateWarn = false;
    try {
      let date = Date.parse(dateString);
      if (!date) {
        log(
          LogLevel.UI_EXCEPTION,
          "Admin:AllInOneOrderList:onDateEndChange",
          dateString
        );
        dateWarn = true;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:AllInOneOrderList:onDateEndChange",
          dateString,
          date
        );
      }
    } catch (err) {
      log(
        LogLevel.UI_EXCEPTION,
        "Admin:AllInOneOrderList:onDateEndChange",
        dateString,
        err
      );
      dateWarn = true;
    }
    let newState: any = {};
    (newState[field] = dateString),
      (newState[field + "Warn"] = dateWarn),
      this.setState(newState);
  };

  onCopy = () => {
    let windowAny: any = window;
    let $ = windowAny.$;
    // window.open('data:application/vnd.ms-excel,' + $('#table').html());
    var body = document.getElementById("admin-table-body");
    var range = document.createRange();
    range.selectNode(body);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    this.props.toastPopup.show("클립보드에 저장되었습니다.(안되었을수도 있음)");
  };

  onDownload = () => {
    this.downloadOrderList();
  };

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "AllInOneOrderList.render",
      this.props,
      this.state
    );

    let more;
    if (!this.state.finished) {
      more = (
        <IonButton expand="full" onClick={this.fetchDataMore}>
          더 불러오기
        </IonButton>
      );
    }
    return (
      <div className="admin-full-container">
        {this.state.type == 1 && (
          <div className="common-container-row-wrap admin-margin-bottom">
            {this.props.onBack && (
              <IonHeader class="common-header" no-border>
                <IonToolbar color="common-header-toolbar">
                  <IonButtons slot="start" class="common-container">
                    <IonButton
                      class="common-header-toolbar-button"
                      onClick={this.props.onBack}
                    >
                      <IonIcon name="arrow-back" mode="ios" />
                    </IonButton>
                    <IonLabel class="common-header-title common-flex-grow">
                      파트너스 주문 목록
                    </IonLabel>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
            )}
            <div className="admin-title">결제기간</div>
            <input
              className={this.state.dateStartWarn ? "common-color-caution" : ""}
              placeholder="시작일시"
              value={this.state.dateStart}
              onChange={(e) => this.onDateChange(e, "dateStart")}
            />
            <div>~</div>
            <input
              className={this.state.dateEndWarn ? "common-color-caution" : ""}
              placeholder="시작일시"
              value={this.state.dateEnd}
              onChange={(e) => this.onDateChange(e, "dateEnd")}
            />
            <div
              className="admin-title common-color-highlight"
              onClick={() => {
                this.setState({
                  dateStart: getDateStringFromToday({ weeks: -1 }),
                  dateEnd: getDateStringFromToday({ days: 1 }),
                  dateStartWarn: false,
                  dateEndWarn: false,
                });
              }}
            >
              1주
            </div>
            <div
              className="admin-title common-color-highlight"
              onClick={() => {
                this.setState({
                  dateStart: getDateStringFromToday({ weeks: -4 }),
                  dateEnd: getDateStringFromToday({ days: 1 }),
                  dateStartWarn: false,
                  dateEndWarn: false,
                });
              }}
            >
              4주
            </div>
            <div
              className="admin-title common-color-highlight"
              onClick={() => {
                this.setState({
                  dateStart: getDateStringFromToday({ byMonth: true }),
                  dateEnd: getDateStringFromToday({ byMonth: true, months: 1 }),
                  dateStartWarn: false,
                  dateEndWarn: false,
                });
              }}
            >
              이번달
            </div>
            <div
              className="admin-title common-color-highlight"
              onClick={() => {
                this.setState({
                  dateStart: getDateStringFromToday({
                    byMonth: true,
                    months: -1,
                  }),
                  dateEnd: getDateStringFromToday({ days: 1 }),
                  dateStartWarn: false,
                  dateEndWarn: false,
                });
              }}
            >
              지난달부터
            </div>
            <div
              className="admin-title common-color-highlight"
              onClick={() => {
                this.setState({
                  dateStart: getDateStringFromToday({
                    byMonth: true,
                    months: -1,
                  }),
                  dateEnd: getDateStringFromToday({ byMonth: true }),
                  dateStartWarn: false,
                  dateEndWarn: false,
                });
              }}
            >
              지난달
            </div>
            <div
              className="admin-title common-color-highlight"
              onClick={() => {
                this.setState({
                  dateStart: getDateStringFromToday({ months: -6 }),
                  dateEnd: getDateStringFromToday({ days: 1 }),
                  dateStartWarn: false,
                  dateEndWarn: false,
                });
              }}
            >
              6개월간
            </div>
          </div>
        )}
        <div className="common-container-row-wrap admin-margin-bottom">
          <div
            className={
              "admin-toggle" +
              (this.state.unitCount == 100 ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ unitCount: 100 })}
          >
            100row
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.unitCount == 5000 ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ unitCount: 5000 })}
          >
            5000row
          </div>
        </div>
        <IonButton onClick={this.fetchData}>불러오기</IonButton>
        <IonButton onClick={this.onCopy}>복사</IonButton>
        <IonButton onClick={this.onDownload}>
          다운로드({this.state.items.length}건)
        </IonButton>

        <div className="common-container">
          {more}
          {this.renderOrderList()}
          {more}
        </div>
        <div style={{ height: "100px" }} />
      </div>
    );
  }

  renderOrderList = () => {
    return (
      <table id="admin-table" className="admin-table">
        <thead className="admin-table-fixed-header">
          <tr>
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrder("id")}
            >
              ID
              {this.state.orderBy == "id"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
            {/* <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrder("id")}
            >
              사용자ID
              {this.state.orderBy == "id"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td> */}
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrder("bizUser.companyName")}
            >
              회사명
              {this.state.orderBy == "bizUser.companyName"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
            {/* <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrder("bizUser.companyName")}
            >
              회사프로필
              {this.state.orderBy == "bizUser.companyProfileName"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td> */}
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrder("paidAt")}
            >
              결제일
              {this.state.orderBy == "paidAt"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
            {/* <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrder("productCode")}
            >
              상품
              {this.state.orderBy == "productCode"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td> */}
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrder("productTitle")}
            >
              상품상세
              {this.state.orderBy == "productTitle"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrder("paidAmount")}
            >
              금액
              {this.state.orderBy == "paidAmount"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrder("payMethod")}
            >
              결제방법
              {this.state.orderBy == "payMethod"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrder("payStatus")}
            >
              결제상태
              {this.state.orderBy == "payStatus"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrder(".startAt")}
            >
              시작일
              {this.state.orderBy == ".startAt"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrder(".endAt")}
            >
              종료일
              {this.state.orderBy == ".endAt"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
          </tr>
        </thead>
        <tbody id="admin-table-body">
          {this.state.items.map((order: OrderInfo, index) => {
            return (
              <tr
                className="admin-table-row"
                key={index.toString()}
                onClick={() => this.props.onSelect(order)}
              >
                <td className="admin-table-value" >
                    <div className={this.checkingEndAt(order.details[0].endAt) ?  "admin-table-value-endAt" : ""}>
                      {order.id}
                    </div>
                </td>
                <td className="admin-table-value" >
                    <div className={this.checkingEndAt(order.details[0].endAt) ?  "admin-table-value-endAt" : ""}>
                      {order.bizUserInfo ? order.bizUserInfo.companyName : ""}
                    </div>
                </td>
                <td className="admin-table-value" >
                  {order.paidAt
                    ? 
                    <div className={this.checkingEndAt(order.details[0].endAt) ?  "admin-table-value-endAt" : ""}>
                      {getDateStringFromToday({ date: order.paidAt })}
                    </div>
                    : ""}
                </td>
                {/* <td className="admin-table-value ">
                  <div style={this.endAtStyle(order.details[0].endAt)}>
                    {order.productCode}
                  </div>
                </td> */}
                <td className="admin-table-value">
                  <div className={this.checkingEndAt(order.details[0].endAt) ?  "admin-table-value-endAt" : ""}>
                    {order.productTitle}
                  </div>
                </td>
                <td className="admin-table-value">
                  <div className={this.checkingEndAt(order.details[0].endAt) ?  "admin-table-value-endAt" : ""}>
                    {order.paidAmount}
                  </div>
                </td>
                <td className="admin-table-value">
                  <div className={this.checkingEndAt(order.details[0].endAt) ?  "admin-table-value-endAt" : ""}>
                    {order.payMethod}
                  </div>
                </td>
                <td className="admin-table-value">
                  <div className={this.checkingEndAt(order.details[0].endAt) ?  "admin-table-value-endAt" : ""}>
                    {order.payStatus}
                  </div>
                </td>
                <td className="admin-table-value">
                  {order.details[0] && order.details[0].startAt
                    ? 
                      <div className={this.checkingEndAt(order.details[0].endAt) ?  "admin-table-value-endAt" : ""}>
                        {getDateStringFromToday({ date: order.details[0].startAt })}
                      </div>
                    : ""}
                </td>
                <td className="admin-table-value">
                  {order.details[0] && order.details[0].endAt
                    ? 
                      // <div style={this.endAtStyle(order.details[0].endAt)}>
                      <div className={this.checkingEndAt(order.details[0].endAt) ?  "admin-table-value-endAt" : ""}>
                        {getDateStringFromToday({ date: order.details[0].endAt })}
                      </div>
                    : ""}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  downloadOrderList = () => {
    let csv =
      "ID,회사명,회사프로필명,결제일,상품,상품상세,금액,결제방법,결제상태,시작일,종료일\r\n";
    csv += this.state.items
      .map((order: OrderInfo, index) => {
        return `${order.id},${
          order.bizUserInfo ? order.bizUserInfo.companyName : ""
        },${order.bizUserInfo ? order.bizUserInfo.companyProfileName : ""},${
          order.paidAt ? getDateStringFromToday({ date: order.paidAt }) : ""
        },${order.productCode},${order.productTitle},${order.paidAmount},${
          order.payMethod
        },${order.payStatus},${
          order.details[0] && order.details[0].startAt
            ? getDateStringFromToday({ date: order.details[0].startAt })
            : ""
        },${
          order.details[0] && order.details[0].endAt
            ? getDateStringFromToday({ date: order.details[0].endAt })
            : ""
        }\r\n`;
      })
      .join("");

    DownloadUtil.downloadCsv(
      csv,
      `JobPost-Offers-${this.state.dateStart}-${
        this.state.dateEnd
      }-${new Date().toISOString().substring(0, 10).replace(/-/gi, "")}.csv`
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AllInOneOrderList);
