import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonModal,
  IonLoading,
  IonToast,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
  IonFooter,
} from "@ionic/react";
import "./ProductSaleOrder.css";
import {
  OrderInfo,
  OrderPayMethod,
  OrderStatus,
  ProductInfo,
  ProductStatus,
} from "../models/Model.Sale";
import { log, LogLevel } from "../utils/LogUtil";
import { UIPopupType, UIServiceType } from "../store/ui/types";
import {
  clearGlobal,
  getGlobal,
  GlobalKey,
  setGlobal,
} from "../utils/GlobalUtil";
import ProductOrder from "../components/sale/ProductOrder";
import GeocodeUtil from "../utils/GeocodeUtil";
import ProductOrderDelivery from "../components/sale/ProductOrderDelivery";
import ProductOrderDone from "../components/sale/ProductOrderDone";
import SearchAddress from "../components/SearchAddress";
import ProductOrderRegistration from "../components/sale/ProductOrderRegistration";
import { UserInfo } from "../models/Model.User";
import { fetchAPI } from "../utils/APIUtil";
import * as API from "./../API.json";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
import AnalyticsUtil from "../utils/AnalyticsUtil";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {};

type State = {
  status: number;
  order: OrderInfo;
  paying: boolean;
};
const windowAny: any = window;
const queryString = require("query-string");

class ProductSaleOrder extends Component<Props, State> {
  onProgress: boolean = false;
  productId: number = 1;
  failed: boolean = false;
  currentLocation: string = "";

  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "ProductSaleOrder.constructor");
    this.state = {
      status: 0,
      order: {
        id: 0,
        status: OrderStatus.NONE,
        amount: 1, // 주문량
        payAmount: 0, // 결제액

        payMethod: OrderPayMethod.CARD, // 결제방법
      },
      paying: false,
    };
    try {
      windowAny.IMP.init("imp68909306");
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, "ProductSaleOrder.init error", e);
    }

    let qs = queryString.parse(this.props.location.search);
    try {
      this.productId = parseInt(qs.id);
    } catch (err) {}
  }

  componentDidMount() {
    log(LogLevel.UI_LIFECYCLE, "ProductSaleOrder.componentDidMount");
    this.props.backKeyControl.setListener(() => {
      this.goBack();
      return false;
    });

    if (this.props.products && this.props.products[this.productId]) {
      if (this.props.products[this.productId].loading)
        this.props.waitingPopup.show();
      else if (this.props.products[this.productId].error) {
        this.props.waitingPopup.show();
        this.props.loadProduct(this.productId);
      } else this.initialize();
    } else {
      this.props.waitingPopup.show();
      this.props.loadProduct(this.productId);
    }
  }

  componentWillUnmount() {
    // this.props.backKeyControl.popListener();
  }

  componentDidUpdate(prevProps, prevState) {
    let qs = queryString.parse(this.props.location.search);
    let newId = this.productId;
    try {
      newId = parseInt(qs.id);
      if (!newId) newId = 0;
    } catch (err) {
      newId = 0;
    }

    //console.log(LogLevel.UI_LIFECYCLE, "Viewer:compoenetDidUpdate new ID ", newId);
    if (this.productId != newId) {
      log(
        LogLevel.UI_LIFECYCLE,
        "ProductSaleOrder:compoenetDidUpdate new ID DETECTED",
        newId,
        this.productId
      );
      if (this.props.products && this.props.products[this.productId]) {
        if (this.props.products[this.productId].loading)
          this.props.waitingPopup.show();
        else if (this.props.products[this.productId].error) {
          this.props.waitingPopup.show();
          this.props.loadProduct(this.productId);
        } else this.initialize();
      } else {
        this.props.waitingPopup.show();
        this.props.loadProduct(this.productId);
      }
    }

    if (!prevProps.backKeyControl && this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.goBack);

    if (
      (!prevProps.products[this.productId] ||
        prevProps.products[this.productId].loading ||
        prevProps.products[this.productId].error) &&
      this.props.products[this.productId] &&
      !this.props.products[this.productId].loading &&
      !this.props.products[this.productId].error
    ) {
      this.initialize();
      this.props.waitingPopup.hide();
    }
  }

  initialize = async () => {
    let product: ProductInfo = this.props.products[this.productId];

    let order: OrderInfo = { ...this.state.order };
    order.userId = this.props.me.id;
    order.productId = product.id;
    order.product = product;
    order.status = OrderStatus.IN_CART;
    order.amount = 1; // 주문량
    order.payAmount = product.price + product.deliveryFee; // 결제액
    order.recipientName = this.props.me.name;
    order.recipientPostalCode = await GeocodeUtil.getPostalCode(
      this.props.me.workPlaceAddress
    );
    order.recipientAddress1 = this.props.me.workPlaceAddress;
    order.recipientAddress2 = this.props.me.workPlaceName;
    order.recipientPhone = this.props.me.phone;
    order.payMethod = OrderPayMethod.CARD; // 결제방법

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "SALE_ORDER_ENTER",
      "제품주문상세페이지 진입",
      {
        제품id: this.productId,
        code: product.advertiserCode,
        productCode: product.advertiserProductCode,
      }
    );

    this.setState({ order });
    this.props.waitingPopup.hide();
  };

  goBack = () => {
    log(
      LogLevel.UI_ACTION,
      "ProductSaleOrder:goBack",
      this.state.status,
      this.props.history,
      this.props.location
    );
    if (this.props.waitingPopup && this.props.waitingPopup.isShown())
      this.props.waitingPopup.hide();
    if (this.state.status == 0) {
      this.currentLocation = this.props.location.pathname;
      // this.props.history.goBack();
      if (this.failed) {
        this.props.waitingPopup.show();
        this.recursiveGoBack();
      } else {
        this.props.history.goBack();
      }
    } else this.setState({ status: 0 });
  };

  recursiveGoBack = () => {
    this.props.history.goBack();
    setTimeout(() => {
      log(
        LogLevel.UI_ACTION,
        "ProductSaleOrder:recursiveGoBack",
        this.currentLocation,
        window.location.pathname
      );
      if (this.currentLocation == window.location.pathname)
        this.recursiveGoBack();
      else this.props.waitingPopup.hide();
    }, 200);
  };

  onClickChangeAddress = () => {
    this.setState({ status: 1 });
  };

  onChangeAddress = (value: any) => {
    log(
      LogLevel.UI_LIFECYCLE,
      "ProductSaleOrder.onChangeAddress",
      value,
      this.state.order
    );
    let order: OrderInfo = {
      ...this.state.order,
      ...value,
    };
    this.setState({ order, status: 0 });
  };

  onClickChangePharmacyRegistration = () => {
    this.setState({ status: 2 });
  };

  onCountChanged = (count: number) => {
    let order: OrderInfo = {
      ...this.state.order,
      amount: count,
    };
    this.setState({ order });
  };

  onOrder = async () => {
    this.props.waitingPopup.show();
    let order: OrderInfo = {
      userId: this.state.order.userId,
      productId: this.state.order.productId,
      status: this.state.order.status,
      amount: this.state.order.amount, // 주문량
      payProduct: this.state.order.product.price * this.state.order.amount, // 총 상품 가격
      payDelivery: this.state.order.product.deliveryFee, // 배송비
      payAmount:
        this.state.order.product.price * this.state.order.amount +
        this.state.order.product.deliveryFee, // 결제액
      // payAmount: 100,     // 결제액
      recipientName: this.state.order.recipientName,
      recipientPostalCode: this.state.order.recipientPostalCode,
      recipientAddress1: this.state.order.recipientAddress1,
      recipientAddress2: this.state.order.recipientAddress2,
      recipientPhone: this.state.order.recipientPhone,
      payMethod: this.state.order.payMethod, // 결제방법
    };
    // log(LogLevel.UI_LIFECYCLE, "ProductSaleOrder.onOrder", order, this.state.order);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "SALE_ORDER_PAY",
      "제품주문결제실행",
      {
        제품id: this.productId,
        code: this.props.products[this.productId].advertiserCode,
        productCode: this.props.products[this.productId].advertiserProductCode,
      }
    );

    let result = await fetchAPI(
      API.SALE_ORDER_ADD,
      "",
      null,
      order,
      getGlobal(GlobalKey.TOKEN)
    );

    if (result && !result.error) {
      order.id = result.data;
    } else {
      alert("주문등록 실패 : 약문약답 카카오플러스친구로 연락주세요.");
      this.props.waitingPopup.hide();
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "SALE_ORDER_FAIL",
        "제품주문결제실패",
        {
          제품id: this.productId,
          code: this.props.products[this.productId].advertiserCode,
          productCode: this.props.products[this.productId]
            .advertiserProductCode,
        }
      );
      return;
    }

    this.props.setOrder({ ...this.state.order, id: order.id });

    order.payMerchantId =
      "ymyd_P" +
      order.productId +
      "_U" +
      order.userId +
      "_O" +
      order.id +
      "_" +
      new Date().getTime();
    order.status = OrderStatus.ORDERED;
    result = await fetchAPI(
      API.SALE_ORDER_CHANGE,
      "",
      null,
      order,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.setOrder(order);
    } else {
      alert("주문 실패 : 약문약답 카카오플러스친구로 연락주세요.");
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "SALE_ORDER_FAIL",
        "제품주문결제실패",
        {
          제품id: this.productId,
          code: this.props.products[this.productId].advertiserCode,
          productCode: this.props.products[this.productId]
            .advertiserProductCode,
        }
      );
      return;
    }

    setGlobal(GlobalKey.SALE_LAST_ORDER_ID, order.id, true);
    if (ABTestUtil.isLocal()) {
      order.payAmount = 100;
    }

    this.setState({ paying: true });

    if (getGlobal(GlobalKey.OS) == "browser") {
      windowAny.IMP.request_pay(
        {
          pg: "danal_tpay",
          pay_method: "card",
          merchant_uid: order.payMerchantId,
          name: this.state.order.product.name,
          amount: order.payAmount,
          // amount : 100,
          buyer_email: this.props.me.email,
          buyer_name: this.props.me.name,
          buyer_tel: this.props.me.phone,
        },
        this.onOrderDoneWeb
      );
    } else {
      var titleOptions = {
        text: "결제진행", // 타이틀
        textColor: "#ffffff", // 타이틀 색
        textSize: "20", // 타이틀 크기
        textAlignment: "left", // 타이틀 정렬 유형
        backgroundColor: "#344e81", // 타이틀 배경색
        show: true, // 타이틀 유무
        leftButtonType: "back", // 왼쪽 버튼 유형
        leftButtonColor: "#ffffff", // 왼쪽 버튼 색
        rightButtonType: "close", // 오른쪽 버튼 유형
        rightButtonColor: "#ffffff", // 오른쪽 버튼 색
      };
      var userCode = "imp68909306"; // 가맹점 식별코드
      var data = {
        pg: "danal_tpay", // PG사
        pay_method: "card", // 결제수단
        name: this.state.order.product.name, // 주문명
        merchant_uid: order.payMerchantId, // 주문번호
        amount: order.payAmount, // 결제금액
        // amount: 100,                              // 결제금액
        buyer_name: this.props.me.name, // 구매자 이름
        buyer_tel: this.props.me.phone, // 구매자 연락처
        buyer_email: this.props.me.email, // 구매자 이메일
        app_scheme: "kakao845dd8ac648986a8631ecadc339a7acf", // 앱 URL 스킴
      };

      var params = {
        titleOptions: titleOptions, // 타이틀 옵션
        userCode: userCode, // 가맹점 식별코드
        data: data, // 결제 데이터
        callback: this.onOrderDoneApp,
      };
      windowAny.cordova.plugins.IamportCordova.payment(params);
    }
  };

  onOrderDoneWeb = async (rsp) => {
    let orderId = getGlobal(GlobalKey.SALE_LAST_ORDER_ID);
    let errorMessage = "",
      error;
    this.setState({ paying: false });

    if (!orderId) {
      errorMessage = "결제정보 유실: ERRORCODE1 - 관리자에게 문의하세요.";
    }

    if (!errorMessage && !this.props.orders[orderId]) {
      errorMessage = "결제정보 유실: ERRORCODE2 - 관리자에게 문의하세요.";
    }

    if (rsp.success) {
      clearGlobal(GlobalKey.SALE_LAST_ORDER_ID, true);
      let order: OrderInfo = { ...this.props.orders[orderId] };
      if (order.payMerchantId != rsp.merchant_uid) {
        alert("결제정보 불일치");
        this.props.waitingPopup.hide();
        return;
      }
      order.status = OrderStatus.PAID;
      order.paidAmount = rsp.paid_amount;
      order.impUid = rsp.imp_uid;
      order.payStatus = rsp.status;
      order.payApplyNumber = rsp.apply_num;
      order.payRecieptUrl = rsp.receipt_url;

      let newOrder: OrderInfo = {
        id: order.id,
        status: OrderStatus.PAID,
        paidAmount: order.paidAmount,
        impUid: order.impUid,
        payStatus: order.payStatus,
        payApplyNumber: order.payApplyNumber,
        payRecieptUrl: order.payRecieptUrl,
      };

      let result = await fetchAPI(
        API.SALE_ORDER_CHANGE,
        "",
        null,
        newOrder,
        getGlobal(GlobalKey.TOKEN)
      );
      if (result && !result.error) {
        this.props.setOrder(order);
      } else {
        alert("주문 실패 : 약문약답 카카오플러스친구로 연락주세요.");
        this.props.waitingPopup.hide();
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "SALE_ORDER_FAIL",
          "제품주문결제실패",
          {
            제품id: this.productId,
            code: this.props.products[this.productId].advertiserCode,
            productCode: this.props.products[this.productId]
              .advertiserProductCode,
          }
        );
        this.failed = true;
        return;
      }

      setGlobal(GlobalKey.SALE_LAST_ORDER_ID, order.id, true);

      var msg = "결제가 완료되었습니다.";
      msg += "고유ID : " + rsp.imp_uid;
      msg += " 상점 거래ID : " + rsp.merchant_uid;
      msg += " 결제 금액 : " + rsp.paid_amount;
      msg += " 카드 승인번호 : " + rsp.apply_num;
      log(LogLevel.UI_ACTION, "onOrderDoneWeb:success", rsp, msg);
      this.props.history.replace("/sale/order/done?id=" + orderId);
    } else {
      var msg = "결제에 실패하였습니다.";
      msg += "에러내용 : " + rsp.error_msg;
      alert(msg);
      log(LogLevel.UI_ACTION, "onOrder:fail", rsp);
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "SALE_ORDER_FAIL",
        "제품주문결제실패",
        {
          제품id: this.productId,
          code: this.props.products[this.productId].advertiserCode,
          productCode: this.props.products[this.productId]
            .advertiserProductCode,
        }
      );
      this.failed = true;
    }
    this.props.waitingPopup.hide();
  };

  onOrderDoneApp = async (rsp) => {
    // imp_success == "true", imp_uid, imp_merchant_uid
    let orderId = getGlobal(GlobalKey.SALE_LAST_ORDER_ID);
    let errorMessage = "",
      error;
    this.setState({ paying: false });
    if (!orderId) {
      errorMessage = "결제정보 유실: ERRORCODE1 - 관리자에게 문의하세요.";
    }

    if (!errorMessage && !this.props.orders[orderId]) {
      errorMessage = "결제정보 유실: ERRORCODE2 - 관리자에게 문의하세요.";
    }

    if (!errorMessage && rsp.imp_success != "true") {
      errorMessage = "결제정보 실패: ERRORCODE1 - 관리자에게 문의하세요.";
    }
    let order: OrderInfo = { ...this.props.orders[orderId] };

    if (rsp.imp_success == "true") {
      clearGlobal(GlobalKey.SALE_LAST_ORDER_ID, true);
      if (order.payMerchantId != rsp.merchant_uid) {
        errorMessage = `결제정보 불일치: ERRORCODE3 ${rsp.merchant_uid}- 관리자에게 문의하세요.`;
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "SALE_ORDER_FAIL",
          "제품주문결제실패",
          {
            제품id: this.productId,
            code: this.props.products[this.productId].advertiserCode,
            productCode: this.props.products[this.productId]
              .advertiserProductCode,
          }
        );
        this.failed = true;
        return;
      }

      order.impUid = rsp.imp_uid;
    }

    let result = await fetchAPI(
      API.SALE_ORDER_PAY_CHECK,
      "",
      { id: orderId, imp_uid: order.impUid, merchant_uid: order.payMerchantId },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      order.paidAmount = result.data.paid_amount;
      order.payStatus = result.data.status;
      order.payApplyNumber = result.data.apply_num;
      order.payRecieptUrl = result.data.receipt_url;

      let newOrder: OrderInfo = result.data;
      (newOrder.status = OrderStatus.PAID),
        (result = await fetchAPI(
          API.SALE_ORDER_CHANGE,
          "",
          null,
          newOrder,
          getGlobal(GlobalKey.TOKEN)
        ));
      if (result && !result.error) {
        this.props.setOrder(newOrder);
      } else {
        alert(
          "주문 실패 ERRORCODE 1 : 약문약답 카카오플러스친구로 연락주세요."
        );
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "SALE_ORDER_FAIL",
          "제품주문결제실패",
          {
            제품id: this.productId,
            code: this.props.products[this.productId].advertiserCode,
            productCode: this.props.products[this.productId]
              .advertiserProductCode,
          }
        );
        this.failed = true;
        return;
      }

      setGlobal(GlobalKey.SALE_LAST_ORDER_ID, order.id, true);

      var msg = "결제가 완료되었습니다.";
      msg += "고유ID : " + rsp.imp_uid;
      msg += " 상점 거래ID : " + rsp.merchant_uid;
      msg += " 결제 금액 : " + rsp.paid_amount;
      msg += " 카드 승인번호 : " + rsp.apply_num;
      log(LogLevel.UI_ACTION, "onOrderDoneWeb:success", rsp, msg);
      this.props.waitingPopup.hide();
      this.props.history.replace("/sale/order/done?id=" + orderId);
    } else {
      alert("주문 실패 ERRORCODE 2: 약문약답 카카오플러스친구로 연락주세요.");
      this.props.waitingPopup.hide();
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "SALE_ORDER_FAIL",
        "제품주문결제실패",
        {
          제품id: this.productId,
          code: this.props.products[this.productId].advertiserCode,
          productCode: this.props.products[this.productId]
            .advertiserProductCode,
        }
      );
      this.failed = true;
      return;
    }
  };

  onOrderFailed = (message) => {};

  onSearchAddress = (name: string, phone: string) => {
    let order: OrderInfo = {
      ...this.state.order,
      recipientName: name,
      recipientPhone: phone,
    };

    this.setState({ order, status: 3 });
  };

  onSearchedAddress = (
    address: string,
    region: string,
    name: string,
    phone: string,
    position: any,
    postalCode: string
  ) => {
    log(
      LogLevel.UI_LIFECYCLE,
      "ProductSaleOrder.onSearchedAddress",
      address,
      region,
      name,
      phone,
      position,
      postalCode
    );
    let order: OrderInfo = {
      ...this.state.order,
      recipientAddress1: address,
      recipientAddress2: name,
      recipientPostalCode: postalCode,
    };
    this.setState({ order, status: 1 });
  };

  onChangeRegistration = async (newMe: UserInfo) => {
    this.props.waitingPopup.show();
    if (newMe.workPlaceRegistrationImageBase64) {
      log(
        LogLevel.UI_EVENT,
        "ProductSaleOrder.onChangeRegistration new image",
        newMe.workPlaceRegistrationImageBase64.length
      );
      let data: any = { path: "registration" };
      data.base64 = newMe.workPlaceRegistrationImageBase64;

      let result = await fetchAPI(
        API.UPLOAD_BASE64,
        "",
        null,
        data,
        getGlobal(GlobalKey.TOKEN)
      );
      if (result && !result.error) {
        let url = result.file;
        log(
          LogLevel.UI_ACTION,
          "ProductSaleOrder.onChangeRegistration.upload success : ",
          result.file
        );
        newMe.workPlaceRegistrationUrl = url;
      } else {
        log(
          LogLevel.UI_EXCEPTION,
          "ProductSaleOrder.onChangeRegistration.upload failed",
          result
        );
      }

      newMe.workPlaceRegistrationImageBase64 = null;
    }

    let result = await fetchAPI(
      API.USER_ME_UPDATE,
      "",
      null,
      newMe,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.loginUser();
    }
    this.props.waitingPopup.hide();
    this.setState({ status: 0 });
  };

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "ProductSaleOrder.render",
      this.props.match,
      this.props.history,
      window.history
    );

    if (this.state.status == 0)
      return (
        <ProductOrder
          paying={this.state.paying}
          order={this.state.order}
          me={this.props.me}
          onCancel={this.goBack}
          onOrder={this.onOrder}
          onClickChangeAddress={this.onClickChangeAddress}
          onClickChangePharmacyRegistration={
            this.onClickChangePharmacyRegistration
          }
          onCountChanged={this.onCountChanged}
        />
      );
    else if (this.state.status == 1)
      return (
        <ProductOrderDelivery
          order={this.state.order}
          me={this.props.me}
          onCancel={this.goBack}
          onDone={this.onChangeAddress}
          onSearchAddress={this.onSearchAddress}
        />
      );
    else if (this.state.status == 2)
      return (
        <ProductOrderRegistration
          me={this.props.me}
          onCancel={this.goBack}
          onDone={this.onChangeRegistration}
        />
      );
    else if (this.state.status == 3)
      return (
        <SearchAddress
          searchType="manual"
          title="배송지 주소 입력"
          withoutDetail={true}
          onCanceled={this.onClickChangeAddress}
          onSelected={this.onSearchedAddress}
        />
      );

    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  products: state.sale.products,
  orders: state.sale.orders,
  links: state.board.links,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  loadProduct: (id: number) => actions.sale.getProduct(id),
  setOrder: (order: OrderInfo) => actions.sale.setOrder(order),
  updateMe: (user: UserInfo) => actions.user.setMe(user),
  loginUser: () => actions.user.login(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductSaleOrder)
);
