import { CompanyType } from "../../models/Model.User";
import {
  BoardContent,
  BoardContentSummary,
  DailyQuiz,
} from "./../../models/Model.Board";

export const partnerThumbnail = (categoryName: string) => {
  switch (categoryName) {
    case "법률":
      return "/images/partners/default_partners_law.png";
    // case "약국":
    //   return "/images/partners/default_partners_pharmacy.png";
    case "컨설팅":
      return "/images/partners/default_partners_consultant.png";
    case "IT서비스":
      return "/images/partners/default_partners_emr.png";
    case "의약외품":
      return "/images/partners/default_partners_etc.png";
    case "세무":
      return "/images/partners/default_partners_finacial.png";
    case "약국체인":
      return "/images/partners/default_partners_frechise.png";
    case "제약사":
      return "/images/partners/default_partners_healthPortion.png";
    case "학회/기관":
      return "/images/partners/default_partners_institution.png";
    case "대출":
      return "/images/partners/default_partners_loan.png";
    case "건강기능식품":
      return "/images/partners/default_partners_pharmaceutical.png";
    case "교육/판매방법":
      return "/images/partners/default_partners_education.png";
    case "도매/몰":
      return "/images/partners/default_partners_distributor.png";
    case "라이프":
      return "/images/partners/default_partners_life.png";
    default:
      return "/images/partners/default_partners.png";
  }
};

export enum BoardType {
  UNION = -1,

  QNA = 1,
  POST = 2,
  NOTICE = 3,
  FAQ = 4,
  REPORT = 5,
  TEST = 6,
  PRODUCT = 7,
  DISCUSSION = 8,
  SEMINAR_QNA = 9,
  CONSULT_QNA = 12,
  PARTY = 13,
  MARKET = 14,
  ALLINONE = 15,
  JVM = 16,

  QURATED_START = 0x0100000,
  MY_POST = 0x0100001,
  MY_REPLY = 0x0100002,
  MY_SCRAP = 0x0100003,
  MY_LIKE = 0x0100004,

  RECOMMEND = 0x0100006,
  LATEST_NOTICE = 0x0100007,

  HOLY_TREE = 0x0100008,
  NOTICE_TAB = 0x0100009,
  UNION_TAB = 0x0100010,
  PARTNERS_HOT = 0x0100011,
  PARTNERS_NEW_CONTENTS = 0x0100012,

  OTHER_POST_START = 0x2000000,
  OTHER_ANSWER_START = 0x4000000,
  ALLINONE_PARTNER_START = 0x8000000,

  NONE = 0,
  ALL = 0x1000000,
}

export enum BoardFilterType {
  NONE = 0x00,
  UNREAD = 0x01,
  UNANSWERED = 0x02,
}

export enum BoardSortType {
  DEFAULT = 0,
  ID_DESC = 0,
  TIME_DESC = 1,
  TIME_ASC = 2,
  LIKE = 3,
  SCRAP = 4,
  METOO = 5,
  VIEW = 6,
  REPLY = 7,
  COMMENT = 8,
  NICE = 9,
  DIGEST = 10,
  SCORE = 11,
}

export const BoardSortTypeName = [
  "최신순",
  "최신순",
  "예전글 순",
  "좋아요 순",
  "스크랩 순",
  "나도궁금해요 순",
  "조회수 순",
  "답변수 순",
  "댓글수 순",
  "추천일 순",
  "선정일 순",
  "점수 순",
];

export interface BoardListState {
  id?: number;
  category?: number;
  categories?: number[];
  name?: string;
  fullname?: string;
  contents?: BoardContent[];
  end?: boolean;
  loading?: boolean;
  needReload?: boolean;
  checksum?: number;
  lastUpdatedTime?: number;
  sort?: BoardSortType;
  filter?: number;
  user?: number;
  lastVisitedTime?: number;
  attribute?: string;

  bizUserId?: string;
  bizCategoryId?: CompanyType;
  image?: any;
}

export interface BoardState {
  boardList: BoardType[];
  selectableBoardList: BoardType[];
  boardListLoaded: boolean;
  boards: any; // [id]: BoardList
  contents: any; // [id]: BoardContent
  links: any; // [url]: Reference
  filePath: any;

  summaryList: string[];
  summaryGroups: any; // [keywords]: BoardContentSummaryGroup
  summaries: any; // [id]: BoardContentSummary

  bizCategoryList: CompanyType[];
  bizBoards: any; // [id]: BoardList

  dailyQuizs: any; // [id]: DailyQuiz
}

export enum BoardActionTypes {
  BOARDS_FETCH = "@@board/BOARDS_FETCH",
  BOARDS_REQUEST = "@@board/BOARDS_REQUEST",
  BOARDS_SUCCESS = "@@board/BOARDS_SUCCESS",
  BOARDS_ERROR = "@@board/BOARDS_ERROR",
  BOARD_REQUEST = "@@board/BOARD_REQUEST",
  BOARD_SUCCESS = "@@board/BOARD_SUCCESS",
  BOARD_ERROR = "@@board/BOARD_ERROR",
  BOARD_REFRESH = "@@board/BOARD_REFRESH",
  BOARD_UNION_CHANGE = "@@board/BOARD_UNION_CHANGE",
  BOARD_SORT = "@@board/BOARD_SORT",
  BOARD_FILTER = "@@board/BOARD_FILTER",
  BOARD_FETCH = "@@board/BOARD_FETCH_LIST",
  BOARD_FETCH_NEW = "@@board/BOARD_FETCH_LIST_NEW",
  BOARD_FETCH_RELOAD = "@@board/BOARD_FETCH_LIST_RELOAD",
  BOARD_VISIT = "@@board/BOARD_VISIT",
  CONTENT_FETCH = "@@board/CONTENT_FETCH",
  CONTENT_REQUEST = "@@board/CONTENT_REQUEST",
  CONTENT_SUCCESS = "@@board/CONTENT_SUCCESS",
  CONTENT_ERROR = "@@board/CONTENT_ERROR",
  CONTENT_UPDATE = "@@board/CONTENT_UPDATE",
  LINK_FETCH = "@@board/LINK_FETCH",
  COMMENT_LINK_FETCH = "@@board/COMMENT_LINK_FETCH",
  LINK_REQUEST = "@@board/LINK_REQUEST",
  LINK_SUCCESS = "@@board/LINK_SUCCESS",
  LINK_ERROR = "@@board/LINK_ERROR",
  FILE_PATH_FETCH = "@@board/FILE_PATH_FETCH",
  FILE_PATH_REQUEST = "@@board/FILE_PATH_REQUEST",
  FILE_PATH_SUCCESS = "@@board/FILE_PATH_SUCCESS",
  FILE_PATH_ERROR = "@@board/FILE_PATH_ERROR",
  BIZ_BOARD_FETCH = "@@board/BIZ_BOARD_FETCH",
  BIZ_BOARD_REQUEST = "@@board/BIZ_BOARD_REQUEST",
  BIZ_BOARD_SUCCESS = "@@board/BIZ_BOARD_SUCCESS",
  BIZ_BOARD_ERROR = "@@board/BIZ_BOARD_ERROR",
  BIZ_BOARD_SORT = "@@board/BIZ_BOARD_SORT",
  SUMMARY_UPDATE = "@@board/SUMMARY_UPDATE",
  SUMMARY_CLEAR = "@@board/SUMMARY_CLEAR",
  SUMMARY_FETCH = "@@board/SUMMARY_FETCH",
  SUMMARY_REQUEST = "@@board/SUMMARY_REQUEST",
  SUMMARY_SUCCESS = "@@board/SUMMARY_SUCCESS",
  SUMMARY_ERROR = "@@board/SUMMARY_ERROR",
  SUMMARY_GROUP_UPDATE = "@@board/SUMMARY_GROUP_UPDATE",
  SUMMARY_GROUP_FETCH = "@@board/SUMMARY_GROUP_FETCH",
  SUMMARY_GROUP_REQUEST = "@@board/SUMMARY_GROUP_REQUEST",
  SUMMARY_GROUP_SUCCESS = "@@board/SUMMARY_GROUP_SUCCESS",
  SUMMARY_GROUP_ERROR = "@@board/SUMMARY_GROUP_ERROR",
  SUMMARY_GROUP_COUNT_FETCH = "@@board/SUMMARY_GROUP_COUNT_FETCH",
  DAILY_QUIZ_FETCH = "@@board/DAILY_QUIZ_FETCH",
  DAILY_QUIZ_REQUEST = "@@board/DAILY_QUIZ_REQUEST",
  DAILY_QUIZ_SUCCESS = "@@board/DAILY_QUIZ_SUCCESS",
  DAILY_QUIZ_ERROR = "@@board/DAILY_QUIZ_ERROR",
  DAILY_QUIZ_UPDATE = "@@board/DAILY_QUIZ_UPDATE",
  DAILY_QUIZ_LIST_FETCH = "@@board/DAILY_QUIZ_LIST_FETCH",
  DAILY_QUIZ_LIST_REQUEST = "@@board/DAILY_QUIZ_LIST_REQUEST",
  DAILY_QUIZ_LIST_SUCCESS = "@@board/DAILY_QUIZ_LIST_SUCCESS",
  DAILY_QUIZ_LIST_ERROR = "@@board/DAILY_QUIZ_LIST_ERROR",
}
