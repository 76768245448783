import { is } from "immutable";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  BottomSheetState,
  hideBottomSheet,
  offBottomSheet,
  onBottomSheet,
  paintBottomSheet,
} from "../store/modal/bottomsheet";
import { UIServiceType } from "../store/ui/types";

const useBottomSheet = () => {
  const dispatch = useDispatch();
  const active = useSelector((state: RootState) => state.bottomSheet.active);
  const activated = useRef<number>(0)
  const backKeyControl = useSelector((state: RootState) => state.ui.services[UIServiceType.BACK_CONTROLLER]);

  useEffect(() => {
    if (backKeyControl) {
      if(active && activated.current == 1){
        backKeyControl.pushListener(() => {
          if (active) {
            close();
          }
  
          return true;
        });
      }
      else if(!active && activated.current == 2){
        backKeyControl.popListener();
      }
    }
  }, [active]);

  useEffect(() => {
    dispatch(onBottomSheet());

    return () => {
      dispatch(offBottomSheet());
    };
  }, []);

  const show = (options?: Partial<BottomSheetState>) => {
      activated.current = 1;
      dispatch(paintBottomSheet(options));
  };

  const close = () => {
      activated.current = 2;
      dispatch(hideBottomSheet());
  };

  return { show, close };
};

export default useBottomSheet;
