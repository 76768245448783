import React, { Component } from "react";
import "./../../Admin.scss";
import "./../../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "../../../utils/APIUtil";
import { timeout } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  AllInOnePartnerLevel,
  CompanyTypeName,
  PARTNERS_TEMPLATE,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import ProfileImage from "../../../components/ProfileImage";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import StringUtil from "../../../utils/StringUtil";
import { RootState } from "../../../store";
import { UIPopupType } from "../../../store/ui/types";
import { connect } from "react-redux";
import ToggleSwitch from "../../../component/atom/ToggleSwitch";
import {
  BizUserInfo,
  CASH_COUPON,
  EMPTY_COUPON,
} from "../../../models/Mode.Biz";
import { COLOR_SYSTEM } from "../../../components/design/design-system";
import { Absolute, Flex, Static } from "../../../components/atoms/Layout";
import {
  BoardContentQuiz,
  DailyQuiz,
  sampleBoardContentQuiz,
} from "../../../models/Model.Board";
import styled from "styled-components";
import Text from "../../../components/atoms/Text";
import ReactHtmlParser from "react-html-parser";
import { type } from "../../../component/atom/Text/index.stories";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import DownloadUtil from "../../../utils/DownloadUtil";
import Tag from "../../../components/atoms/Tag";
import ToggleButton from "../../../components/atoms/ToggleButton/ToggleButton";
import { stat } from "fs";
import Button from "../../../components/atoms/Button";
import Input from "../../../components/atoms/Input";

const AdminAPI = {
  SUMMARY_LIST: {
    method: "GET",
    path: "/admin/quiz/list",
    contentType: "application/json",
  },
};

enum QuizStatus {
  VERIFIED = 0,
  DELETED = 1,
  CREATED = 2,
}

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onSelect: (quiz: BoardContentQuiz) => void;
    onAdd?: (quiz: BoardContentQuiz) => void;
    onClose?: () => void;
    quiz: BoardContentQuiz;
    contentId: number;
  };

type State = {
  searchContentId: number;
  quizs: BoardContentQuiz[];
  finished: boolean;
  filter: QuizStatus[];
  count: number;
  statistics: number[];
  keyword: string;
};

class QuizSelect extends Component<Props, State> {
  loading = false;

  state = {
    searchContentId: 0,
    quizs: [],
    finished: false,
    filter: [QuizStatus.DELETED, QuizStatus.CREATED, QuizStatus.VERIFIED],
    count: 100,
    keyword: "",
    statistics: [0, 0, 0],
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.contentId != this.props.contentId) {
      this.setState({ searchContentId: this.props.contentId });
      this.fetchData();
    }
  }

  onKeyPress = (e) => {
    log(LogLevel.UI_ACTION, "QuizSelect:onKeyPress", e.keyCode, e.shiftKey);
    if (e.keyCode == 13) {
      this.fetchData();
      e.preventDefault();
    }
  };

  onSearchContentIdChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let num = e.detail.value;
    this.setState({ searchContentId: parseInt(num) });
  };

  refresh = (quiz?: BoardContentQuiz) => {
    if (quiz) {
      const index = this.state.quizs.findIndex(
        (item: BoardContentQuiz) => item.id === quiz.id
      );
      if (index >= 0) {
        let newQuizs = [...this.state.quizs];
        newQuizs[index] = quiz;

        const statistics = this.getStatistics(newQuizs);
        this.setState({ quizs: newQuizs, statistics });
      } else {
        const quizs = [quiz, ...this.state.quizs];
        const statistics = this.getStatistics(quizs);

        this.setState({ quizs, statistics });
      }
    } else {
      this.fetchData();
    }
  };

  download = () => {
    log(LogLevel.UI_ACTION, "Admin:QuizSelect:download", this.state.quizs);
    let text = "ID\tContentID\tVersion\tJson\n";
    this.state.quizs.map((item: BoardContentQuiz) => {
      text += `${item.id}\t${item.contentId}\t${item.version}\t${JSON.stringify(
        item
      )}\n`;
    });
    DownloadUtil.downloadCsv(
      text,
      `Summary-${new Date()
        .toISOString()
        .substring(0, 10)
        .replace(/-/gi, "")}.csv`
    );
  };

  search = (option: State) => {
    log(LogLevel.UI_DATA_LOAD, "Admin:QuizSelect:search", option);

    this.setState(option);
    setTimeout(this.refresh, 100);
  };

  fetchData = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:QuizSelect:fetchData");
    this.loading = true;

    let parameter: any = {
      count: this.state.count,
      start: 0,
      includeManagers: true,
      keyword: this.state.keyword,
    };

    if (this.state.searchContentId) {
      parameter.contentId = this.state.searchContentId;
    }

    let finished = false;

    fetchAPI(
      AdminAPI.SUMMARY_LIST,
      "",
      parameter,
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      if (result && !result.error) {
        log(LogLevel.UI_DATA_LOAD, "Admin:QuizSelect:fetchData result", result);
        if (result.data && result.data.length < this.state.count)
          finished = true;
        const quizs = result.data;
        const statistics = this.getStatistics(quizs);
        this.setState({ quizs, statistics, finished });
        this.loading = false;
      } else {
        log(LogLevel.UI_DATA_LOAD, "Admin:QuizSelect:fetchData result", result);
        finished = true;
        this.setState({ quizs: [], finished, statistics: [0, 0, 0] });
      }
    });
  };

  fetchDataMore = async () => {
    log(
      LogLevel.UI_DATA_LOAD,
      "Admin:QuizSelect:fetchDataMore",
      this.state.finished
    );
    this.loading = true;

    let parameter: any = {
      count: this.state.count,
      start: this.state.quizs.length,
      includeManagers: true,
      keyword: this.state.keyword,
    };

    if (this.state.searchContentId) {
      parameter.contentId = this.state.searchContentId;
    }

    let finished = false;

    if (!this.state.finished) {
      fetchAPI(
        AdminAPI.SUMMARY_LIST,
        "",
        parameter,
        null,
        getGlobal(GlobalKey.TOKEN)
      ).then((result) => {
        if (result && !result.error) {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:QuizSelect:fetchData result",
            result
          );

          if (result.data && result.data.length < this.state.count)
            finished = true;

          const quizs = [...this.state.quizs, ...result.data];
          const statistics = this.getStatistics(quizs);
          this.setState({
            quizs,
            statistics,
            finished,
          });
          this.loading = false;
        } else {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:QuizSelect:fetchData result",
            result
          );
          finished = true;
          this.setState({
            finished,
          });
          this.loading = false;
        }
      });
    }
  };

  getStatistics = (quiz: BoardContentQuiz[]) => {
    let statistics = [0, 0, 0];
    quiz.map((item: BoardContentQuiz) => {
      if (item.deletedAt) statistics[QuizStatus.DELETED]++;
      else if (item.verified) statistics[QuizStatus.VERIFIED]++;
      else statistics[QuizStatus.CREATED]++;
    });
    return statistics;
  };

  clearSearch = (key: string) => () => {
    let newState = {};
    if (key == "searchContentId") newState = { searchContentId: 0 };
    this.setState(newState);
  };

  onSelect = (quiz: BoardContentQuiz) => {
    log(LogLevel.UI_EVENT, "Admin:QuizSelect:onSelect", quiz);
    this.props.onSelect(quiz);
  };

  endAtStyle = (endAt: string) => {
    const endDate = new Date(endAt);
    const currentDate = new Date();

    const diffInTime = endDate.getTime() - currentDate.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    log(
      LogLevel.ALL,
      "AllInOneOrderList:diffInDays",
      diffInDays,
      endAt,
      currentDate,
      endDate,
      diffInTime
    );

    if (diffInDays < 0) {
      return {
        backgroundColor: COLOR_SYSTEM.get("Red")[50],
      };
    }
    return {};
  };

  render() {
    log(LogLevel.UI_DATA_LOAD, "Admin:QuizSelect:render", this.state.quizs);
    return (
      <div className="admin-container">
        {this.props.onClose && (
          <IonHeader class="common-header" no-border>
            <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  class="common-header-toolbar-button"
                  onClick={this.props.onClose}
                >
                  <IonIcon name="arrow-back" mode="ios" />
                </IonButton>
                <IonLabel
                  class="common-header-title"
                  onClick={this.props.onClose}
                >
                  퀴즈 목록
                </IonLabel>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        <div className="admin-item-container">
          Content
          <IonInput
            class="admin-input"
            placeholder="ContentId"
            value={this.state.searchContentId.toString()}
            onIonChange={this.onSearchContentIdChange}
            onKeyDown={this.onKeyPress}
            type="number"
            style={{ marginLeft: "10px" }}
          />
          <IonButton
            fill="clear"
            color="primary"
            onClick={this.clearSearch("searchContentId")}
          >
            <IonIcon name="close" />
          </IonButton>
          <div style={{ flex: 1 }}></div>
          <ToggleButton
            color={"Skyblue"}
            size={"Small"}
            variant={"Tinted"}
            style={{ marginRight: "8px" }}
            active={this.state.filter.includes(QuizStatus.DELETED)}
            onClick={() => {
              if (this.state.filter.includes(QuizStatus.DELETED)) {
                this.setState({
                  filter: this.state.filter.filter(
                    (item) => item != QuizStatus.DELETED
                  ),
                });
              } else {
                this.setState({
                  filter: [...this.state.filter, QuizStatus.DELETED],
                });
              }
            }}
          >
            삭제({this.state.statistics[QuizStatus.DELETED]})
          </ToggleButton>
          <ToggleButton
            color={"Skyblue"}
            size={"Small"}
            variant={"Tinted"}
            style={{ marginRight: "8px" }}
            active={this.state.filter.includes(QuizStatus.CREATED)}
            onClick={() => {
              if (this.state.filter.includes(QuizStatus.CREATED)) {
                this.setState({
                  filter: this.state.filter.filter(
                    (item) => item != QuizStatus.CREATED
                  ),
                });
              } else {
                this.setState({
                  filter: [...this.state.filter, QuizStatus.CREATED],
                });
              }
            }}
          >
            미확정({this.state.statistics[QuizStatus.CREATED]})
          </ToggleButton>
          <ToggleButton
            color={"Skyblue"}
            size={"Small"}
            variant={"Tinted"}
            style={{ marginRight: "8px" }}
            active={this.state.filter.includes(QuizStatus.VERIFIED)}
            onClick={() => {
              if (this.state.filter.includes(QuizStatus.VERIFIED)) {
                this.setState({
                  filter: this.state.filter.filter(
                    (item) => item != QuizStatus.VERIFIED
                  ),
                });
              } else {
                this.setState({
                  filter: [...this.state.filter, QuizStatus.VERIFIED],
                });
              }
            }}
          >
            확정({this.state.statistics[QuizStatus.VERIFIED]})
          </ToggleButton>
        </div>
        <div className="common-container common-flex-row common-flex-align-center">
          검색어
          <Input
            style={{ width: "50%" }}
            placeholder="검색어를 입력하세요"
            value={this.state.keyword}
            onChange={(e) => {
              this.setState({ keyword: e.target.value });
            }}
            onClear={() => {
              this.setState({ keyword: "" });
            }}
          />
        </div>
        <div className="common-container common-flex-row common-flex-align-center">
          <IonButton
            color="primary"
            onClick={() => {
              this.refresh();
            }}
          >
            새로불러오기
          </IonButton>
          <IonButton color="primary" onClick={this.download}>
            다운로드
          </IonButton>
          <div className="common-flex-grow" />
          <div className="common-flex-row common-flex-align-center">
            Rows :&nbsp;
            <ToggleButton
              color={"Skyblue"}
              size={"Small"}
              variant={"Outlined"}
              style={{ marginRight: "8px" }}
              active={this.state.count == 100}
              onClick={() => {
                this.setState({
                  count: 100,
                });
              }}
            >
              100
            </ToggleButton>
            <ToggleButton
              color={"Skyblue"}
              size={"Small"}
              variant={"Outlined"}
              style={{ marginRight: "8px" }}
              active={this.state.count == 1000}
              onClick={() => {
                this.setState({
                  count: 1000,
                });
              }}
            >
              1000
            </ToggleButton>
          </div>
        </div>

        <div className="common-container">
          {this.state.quizs.length > 0 && !this.state.finished && (
            <IonButton
              color="primary"
              onClick={this.fetchDataMore}
              style={{ width: "100%" }}
            >
              더 불러오기
            </IonButton>
          )}
          <IonList>
            {this.state.quizs.map((item: BoardContentQuiz, index: number) => {
              // log(
              //   LogLevel.UI_DATA_LOAD,
              //   "Admin:QuizSelect:render Items",
              //   item
              // );
              if (
                !this.state.filter.includes(QuizStatus.DELETED) &&
                item.deletedAt
              )
                return null;
              else if (
                !this.state.filter.includes(QuizStatus.VERIFIED) &&
                item.verified
              )
                return null;
              else if (
                !this.state.filter.includes(QuizStatus.CREATED) &&
                !item.verified &&
                !item.deletedAt
              )
                return null;

              return (
                <QustionWrapper
                  key={index.toString()}
                  onClick={() => this.onSelect(item)}
                  deleted={item.deletedAt}
                  selected={this.props.quiz && item.id == this.props.quiz.id}
                  verified={item.verified}
                >
                  <Text
                    textType="CaptionBold"
                    color={COLOR_SYSTEM.get("Blue")[400]}
                  >
                    ({item.id} - C{item.contentId} - V{item.version} -{" "}
                    {getDateStringFromToday({
                      date: item.createdAt,
                      showTime: true,
                    })}
                    )
                  </Text>
                  <Text
                    textType="Body1SemiBold"
                    color={COLOR_SYSTEM.get("Gray")[900]}
                    numberOfLines={2}
                  >
                    <Tag
                      variant={"Outlined"}
                      color={"Black"}
                      size="Medium"
                      style={{ marginRight: "8px" }}
                    >
                      {item.type}
                    </Tag>
                    {item.question &&
                      ReactHtmlParser(
                        item.question
                          .replace(/\n/gi, "<br/>")
                          .replace(/\\\\n/gi, "<br/>")
                          .replace(/\\n/gi, "<br/>"),
                        {}
                      )}
                  </Text>
                  <Flex gap="8px">
                    {item.createdBy > 0 && !!item.createdByUser && (
                      <Tag
                        variant={"Outlined"}
                        color={"Gray"}
                        size="Medium"
                        style={{ marginRight: "8px" }}
                      >
                        {item.createdByUser.name} 생성
                      </Tag>
                    )}
                    {item.verifiedBy > 0 && !!item.verifiedByUser && (
                      <Tag
                        variant={"Outlined"}
                        color={"Blue"}
                        size="Medium"
                        style={{ marginRight: "8px" }}
                      >
                        {item.verifiedByUser.name} 확정
                      </Tag>
                    )}
                    {item.deletedBy > 0 && !!item.deletedByUser && (
                      <Tag
                        variant={"Outlined"}
                        color={"Red"}
                        size="Medium"
                        style={{ marginRight: "8px" }}
                      >
                        {item.deletedByUser.name} 삭제
                      </Tag>
                    )}
                  </Flex>
                  {this.props.onAdd && item.verified && !item.deletedAt && (
                    <Absolute top="5px" right="5px">
                      <Button
                        size={"Medium"}
                        type={"Icon"}
                        icon="Plus"
                        variant={"Tinted"}
                        color={"Primary"}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.onAdd(item);
                        }}
                      />
                    </Absolute>
                  )}
                </QustionWrapper>
              );
            })}
          </IonList>
          {this.state.quizs.length > 0 && !this.state.finished && (
            <IonButton
              color="primary"
              onClick={this.fetchDataMore}
              style={{ width: "100%" }}
            >
              더 불러오기
            </IonButton>
          )}
        </div>
      </div>
    );
  }
}

const QustionWrapper = styled.div<{
  deleted: boolean;
  selected: boolean;
  verified: boolean;
}>`
  flex-grow: 1;
  padding: 16px;
  background: ${({ deleted, verified }) =>
    COLOR_SYSTEM.get(deleted ? "Red" : verified ? "Green" : "Gray")[10]};
  border-radius: 12px;
  margin-bottom: 8px;
  position: relative;
  ${({ selected }) =>
    selected && `border: 2px solid ${COLOR_SYSTEM.get("Blue")[400]};`}
`;

const mapStateToProps = (state: RootState) => ({
  // filePath: state.board.filePath,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(QuizSelect);
