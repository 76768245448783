import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BoardSummaryGroup from "../../components/templates/BoardSummaryGroup";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { RootState, actions } from "../../store";
import { UIServiceType } from "../../store/ui/types";
import { LogLevel, log } from "../../utils/LogUtil";
import {
  BoardContent,
  BoardContentSummaryGroup,
} from "../../models/Model.Board";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { fetchAPI } from "../../utils/APIUtil";
import { GlobalKey, getGlobal } from "../../utils/GlobalUtil";
import * as API from "../../API.json";
import BoardUtil from "../../utils/BoardUtil";
import { refreshBoards, updateContent } from "../../store/board/actions";
import { BoardType } from "../../store/board/types";
import AdsUtil from "../../utils/AdsUtil";
import { getDateStringFromToday, getDateToDays } from "../../utils/TimeUtil";
import DailyQuizHistory from "../../components/templates/DailyQuiz/History";
import DailyQuizCover from "../../components/templates/DailyQuiz/Cover";
import { on } from "cluster";
import { set } from "lodash";

const windowAny: any = window;

const DailyQuiz: React.FC<RouteComponentProps> = ({
  history,
  location,
  match,
}) => {
  const dispatch = useDispatch();

  const me = useSelector((state: RootState) => state.user.me);

  const backKeyControl = useSelector(
    (state: RootState) => state.ui.services[UIServiceType.BACK_CONTROLLER]
  );

  const today = getDateStringFromToday();
  const date = useMemo(() => {
    const qs = queryString.parse(location.search);
    return qs.date ? (qs.date as string) : today;
  }, [location.search]);

  const dailyQuizId = useMemo(() => {
    return getDateToDays(date);
  }, [date]);

  const dailyQuiz = useSelector(
    (state: RootState) => state.board.dailyQuizs[dailyQuizId]
  );

  const dailyQuizHistory = useSelector(
    (state: RootState) => state.board.dailyQuizs
  );

  const [page, setPage] = useState<"COVER" | "TEST" | "REPORT">("COVER");
  useEffect(() => {
    if (backKeyControl)
      backKeyControl.setListener(() => {
        log(LogLevel.UI_LIFECYCLE, "DailyQuiz:back key callback");
        goBack();
      });

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "DAILY_QUIZ_START",
      "데일리퀴즈_진입",
      {}
    );
  }, []);

  useEffect(() => {
    log(
      LogLevel.UI_LIFECYCLE,
      "DailyQuiz:useEffect [dailyQuiz] start ",
      date,
      dailyQuiz
    );
    if (!dailyQuiz || !dailyQuiz.quizzes) {
      log(LogLevel.UI_LIFECYCLE, "DailyQuiz:useEffect [dailyQuiz] load", date);
      dispatch(actions.board.loadDailyQuiz(date));
    }
  }, [dailyQuiz, date]);

  const goBack = () => {
    history.goBack();
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "DAILY_QUIZ_END",
      "데일리퀴즈_나가기",
      {
        date,
      }
    );
  };

  const onChangePage = (index: number) => {
    // 무료요약 차감
    // if (!summaryGroup.summaries[index]) return;
    // const summary = summaries[summaryGroup.summaries[index].id];
    // if (summary && summary.state != "START" && !summary.viewed) {
    //   log(
    //     LogLevel.UI_LIFECYCLE,
    //     "BoardSummaryPopupPage:useEffect [summary.state != START && !summary.viewed && freeSummaryCount > 0]",
    //     summary,
    //     freeSummaryCount
    //   );
    //   if (!me.premiumType && freeSummaryCount > 0) {
    //     dispatch(actions.user.decreaseFreeSummaryCount());

    //     AnalyticsUtil.event(
    //       AnalyticsUtil.TYPE_ALL,
    //       "GPT_SUMMARY_FREE",
    //       "무료요약차감",
    //       {
    //         from: "모아보기",

    //         게시물id: summary.id,
    //       }
    //     );
    //   }

    //   if (me.premiumType || freeSummaryCount > 0) {
    //     dispatch(actions.board.setSummary({ id: summary.id, viewed: true }));
    //     fetchAPI(
    //       API.ACTION_SUMMARY,
    //       summary.id,
    //       null,
    //       null,
    //       getGlobal(GlobalKey.TOKEN)
    //     );
    //   }
    // }

    // // 읽은 페이지 저장
    // if (index >= 0) {
    //   fetchAPI(
    //     API.ACTION_SUMMARY_GROUP,
    //     "",
    //     null,
    //     {
    //       keyword: summaryGroup.keyword,
    //       value: index,
    //       type: "view",
    //     },
    //     getGlobal(GlobalKey.TOKEN)
    //   );

    //   dispatch(
    //     actions.board.setSummaryGroup({
    //       keyword: summaryGroup.keyword,
    //       lastViewed: index,
    //     })
    //   );
    // }

    // let qs = queryString.parse(location.search);
    // qs["page"] = index.toString();
    // // setPageIndex(index);

    // history.replace(location.pathname + "?" + queryString.stringify(qs));
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "DAILY_QUIZ_MOVE",
      "데일리퀴즈_페이지이동",
      {
        date,
      }
    );
  };

  const onClickReference = (contentId: number) => {
    let url = "/boards/view?id=" + contentId;

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "DAILY_QUIZ_REFERENCE",
      "데일리퀴즈_원문보기",
      {
        url: url,
      }
    );
    history.push(url);
  };

  const onReport = (message: string) => {
    // const summary = summaries[summaryGroup.summaries[pageIndex].id];
    // fetchAPI(
    //   API.BOARDS_POST_SUMMARY_REPORT,
    //   "",
    //   null,
    //   { memo: message, contentId: summary.id },
    //   getGlobal(GlobalKey.TOKEN)
    // );
    // AnalyticsUtil.event(
    //   AnalyticsUtil.TYPE_ALL,
    //   "GPT_SUMMARY_GROUP_REFERENCE",
    //   "요약모아보기_오류보고",
    //   {
    //     keyword: keyword,
    //     게시물id: summary.id,
    //     memo: message,
    //   }
    // );
  };

  log(LogLevel.UI_LIFECYCLE, "DailyQuiz:render [index] ", date);

  if (page === "COVER") {
    return (
      <DailyQuizCover
        premium={me.premiumType}
        dailyQuizList={dailyQuizHistory}
        dailyQuiz={dailyQuiz}
        onLoad={(options: { startDate: string; endDate: string }) => {
          dispatch(actions.board.loadDailyQuizList(options));
        }}
        onClick={(quiz: any) => {}}
        onPremium={() => {}}
        onStart={() => {
          setPage("TEST");
        }}
      />
    );
  }
  return (
    <div>
      <p>{JSON.stringify(dailyQuiz, null, "\t")}</p>
      <DailyQuizHistory
        premium={me.premiumType}
        dailyQuizList={dailyQuizHistory}
        onLoad={(options: { startDate: string; endDate: string }) => {
          dispatch(actions.board.loadDailyQuizList(options));
        }}
        onClick={(quiz: any) => {}}
        onPremium={() => {}}
      />{" "}
    </div>
  );
};

export default withRouter(DailyQuiz);
